import React from "react";
import Header from "../../header/header";
import Footer from "../../Footer/footer";
import Panckosh from "../../page/panchkosh/panchkosh";

const PunchkoshPage = () => {
    return(
        <div>
        <Header />
            <Panckosh />
        <Footer />
    </div>
    )
}
export default PunchkoshPage;