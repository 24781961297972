import React from "react";
import Header from "../../header/header";
import Footer from "../../Footer/footer";
import Home from "./home";

const HomePage = () => {
    return(
        <div>
        <Header />
            <Home />
        <Footer />
    </div>
    )
}
export default HomePage;