import React from "react";
import Header from "../../header/header";
import Footer from "../../Footer/footer";
import Pripping from "./pripping";

const PrippingPage = () => {
    return(
        <div>
        <Header />
            <Pripping />
        <Footer />
    </div>
    )
}
export default PrippingPage;