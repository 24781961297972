import React,{useState} from 'react';
import '../../page/home/home.css';
import { Button, Image } from 'react-bootstrap';
import { } from 'bootstrap';
import '../divineodyssey/divine.css';

const Naddis = () => {
   const [readMore,setReadMore]=useState(false);
   const extraContent=
   <div>
      <p className='extra-content about discription'>
         Normally, during breathing when left nostrils is open it’s the lunar energy or ida nadi is flowing, and when right is free solar energy or pingla is flowing. Pingala represents the positive polarity, dynamic activity, hot in nature and corresponds to sympathetic nervous system. Ida is cold in nature, represent negative polarity, relaxation, passivity and corresponds to parasympathetic nervous system. When one is physically active, right nostril or pingala flows, when sleeping or drowsy left nostril, ida is dominating. Balance and simultaneous flowing of two nadis just like positive and negative currents in an electric circuit, causes stability and awakening of kundalini shakti through sushumna nadi. The ida force controls the manomaya (mental body) and vijanamaya kosha (astral body) while pingala controls the annamaya( physical body) and anandamaya kosha (bliss body).
      </p>
      <p className='extra-content about discription'>
         Awakening of kundalini the mother source of all pranic energy takes place from mooladhara chakra through sushumna to sahasrara chakra. Activating of all the chakras, nadis is very important to the smooth awakening of kundalini shakti as the nine-tenth of inactive unused brain awakens, the entire store of higher energy is unleashed in human being. Pingala is life and consciousness is ida, so balance of both evolves the pranic shakti to kundalini or cosmic consciousness. As we unlock this storehouse of cosmic consciousness, complete evolution of the man take place and becomes aware of the oneness with the whole cosmos. And as these dormant areas are awakened, mental and pranic forces undergo metamorphosis and the whole city of the soul is illuminated, opening the secrets doors to higher, divine selves and dimensional planes.
      </p>
      <p className='extra-content about discription'>
         During pranakarma and meditation, prana is forced to flow through blocked areas (by karmic seeds, latent sanskaras, low emotions waves, unchecked negative thoughts) and purification is achieved at pranic, mental and physical levels. Only after this purification , awakening of sushumna and kundalini takes place.
      </p>
      <p className='extra-content about discription'>
         <i>Some references taken from the book “Prana and Pranayama “ by Swami Niranjanananda Saraswati and “Kundalini Tantra” by Swami Satyananda Saraswati</i>
      </p>
   </div>
   const linkName=readMore?'Read Less':'Read More'
   return (
      <div>
         <div className='row'>
            <div className='col-md-12'>
               <div className='aboutHeading'>
                  <div className='heading'>Divine Oddyssey</div>
               </div>
               <div class="selcetrowborder">
                  <div class="selecterpoiner">
                     <div class="seletbarrow"></div>
                     <p class="textor">
                        <Image className='homeimage' src="/lotus1.png" alt="image"></Image>
                     </p>
                     </div>
               </div>
               <div className='image-section'>
                  <Image className='homeimage' src="/coursespagedesign.png" alt="image"></Image>
               </div>
               <div className='aboutdiscription'>
                  <div className='aboutchakars'>
                     <div className='aboutchakrasHeading'>
                        <h1>NADDIS</h1>
                     </div>
                     <p>
                        Nadis simply means channels for the flow of energy. The rush of energy we experience during moments of exhilaration is through these channels. As our concentration goes deeper during meditation, we can trace the flow in form of vibrations. Modern science explains it in the form of nerve impulses experienced in form of subtle flow of energy just like electricity, radio waves and laser beams. According to tantras, 72000 Nadis and much more, forms the networks in pranayama kosha (Energy Body) covering the whole frame through which the stimuli flows like an electric current. They are the pathways of pranic, mental and spiritual currents providing energy to each and every organs and cell of the physical body. At psychic level they are perceived as rivers of light, colours and sound while at physical level they are involved in all bodily functions and processes.
                     </p>
                     <p>
                        Within this network of Nadis, there are ten main channels and of those ten, three are most important for they control the flow of prana and consciousness within all the other nadis in body. The three Nadis are Ida (Manas Shakti or Mental Force), Pingala (Prana Shakti or vital force) and Sushumna (Kundalini shakti or spiritual force). As sushumna flows inside the central canal of the spinal cord, ida and pingla simultaneously flow on the outer surface of spinal cord, still within the bony vertebral column. Three of them begins in mooldhara in the pelvic floor. From there, sushumna directly flows upwards within the central canal, while ida (female energy) passes to the left and pingla (male energy) to the right.
                     </p>                     
                     {readMore && extraContent}
                     <span className='readMorePosition'>
                        <Button className='btnyog read-more-link' onClick={()=>{setReadMore(!readMore)}} variant="link"><span>{linkName}</span></Button>
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
export default Naddis;