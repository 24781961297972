import config from "../config/config.json";

export const blogServices = {
  blogLists,
  singleBlogDetails
};

async function blogLists() {
  const data = await fetch(`${config.API_URL}blog/blogLists`,
    headers("GET"));
  return await data.json()
}

async function singleBlogDetails(id) {
  const data = await fetch(`${config.API_URL}blog/blogDetails/${id}`,
    headers("GET"));
  return await data.json()
}

function headers(method, blogData) {
  return {
    method: method,
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(blogData),
  };
}