import React from "react";
import Header from "../../header/header";
import Footer from "../../Footer/footer";
import Divine from '../../page/divineodyssey/divine';

const DivinePage = () => {
    return(
        <div>
        <Header />
            <Divine />
        <Footer />
    </div>
    )
}
export default DivinePage;