import React from 'react';
import { Image, Col, Row } from 'react-bootstrap';
// import { NavLink } from 'react-router-dom';
// import { blogServices } from "../../../_services/blogServices.js"
import './blog.css';

function Blogs() {
    // const [items, setitems] = useState([]);

    // useEffect(() => {
    //     blogLists()
    // }, [])

    // const blogLists = async (e) => {
    //     let data = await blogServices.blogLists(e);
    //     setitems(data.user_data);
    // };

    return (
        <div>
            <div className='container'>
                <Row>
                    <Col md={12} className='mb-5'>
                        <div className='aboutblogHeading'>
                            <div className='heading'>Divine Wisdom</div>
                        </div>
                        <div class="selcetrowborder">
                            <div class="selecterpoiner">
                                <div class="seletbarrow"></div>
                                <p class="textor">
                                    <Image className='homeimage' src="/lotus1.png" alt="image"></Image>
                                </p>
                            </div>
                        </div>
                        {/* {items &&
                            items?.map((item, key) => (
                                <div className='bolg1content'>
                                    <div className='about1log'>
                                        <h3>{item.title}</h3>
                                        {item.description.substring(0, 250)}...
                                        <span className='readMoreButtonPosition'>
                                            <NavLink className='moretext more_text' to={"/single-post/" + item.id} variant="link">Read More</NavLink>
                                        </span>
                                    </div>
                                    <div className='firstblogheading'>
                                        <h2 className='blognumber'>{key + 1}</h2>
                                    </div>
                                    <div className='bolgtitle'>
                                        <div className='bolg1title'>BLOG</div>
                                    </div>
                                </div>
                            ))
                        } */}
                        {/* <Row>
                            <Col md={4}>
                                <Card style={{ margin: '10px 20px' }}>
                                    <Card.Img variant="top" src="/yog.jpg" alt='' />
                                    <Card.Body>
                                        <Card.Title>International Yoga Day: Outstanding Yogasanas You Should Perform Daily For Overall Wellbeing</Card.Title>
                                        <Card.Text>
                                            International Yoga Day is commemorated annually on June 21, all across the globe to spread awareness among the populace about the importance of Yoga...
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Body>
                                        <NavLink className='moretext more_text' to={"/single-post/"} variant="link">Read More</NavLink>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card style={{ margin: '10px 20px' }}>
                                    <Card.Img variant="top" src="/yog.jpg" alt='' />
                                    <Card.Body>
                                        <Card.Title>International Yoga Day: Outstanding Yogasanas You Should Perform Daily For Overall Wellbeing</Card.Title>
                                        <Card.Text>
                                            International Yoga Day is commemorated annually on June 21, all across the globe to spread awareness among the populace about the importance of Yoga...
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Body>
                                        <NavLink className='moretext more_text' to={"/single-post/"} variant="link">Read More</NavLink>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card style={{ margin: '10px 20px' }}>
                                    <Card.Img variant="top" src="/yog.jpg" alt='' />
                                    <Card.Body>
                                        <Card.Title>International Yoga Day: Outstanding Yogasanas You Should Perform Daily For Overall Wellbeing</Card.Title>
                                        <Card.Text>
                                            International Yoga Day is commemorated annually on June 21, all across the globe to spread awareness among the populace about the importance of Yoga...
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Body>
                                        <NavLink className='moretext more_text' to={"/single-post/"} variant="link">Read More</NavLink>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row> */}
                        {/* <div className='bolg2content'>
                            <div className='about2log'>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <span className='readMoreButtonPosition'>
                                    <Button className='moretext' variant="link">Read More</Button>
                                </span>
                            </div>
                            <div className='secondblogheading'>
                                <h2 className='blognumber'>02</h2>
                            </div>
                            <div className='bolgtitle2'>
                                <div className='bolg1title'>BLOG</div>
                            </div>
                        </div> */}
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default Blogs;
