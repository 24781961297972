import React from "react";
import Header from "../../header/header";
import Footer from "../../Footer/footer";
import Prana from "../../page/prana/prana";

const PranaPage = () => {
    return(
        <div>
        <Header />
            <Prana />
        <Footer />
    </div>
    )
}
export default PranaPage;