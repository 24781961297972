import React, { useState } from 'react';
import '../../page/home/home.css';
import { Button, Image } from 'react-bootstrap';
import { } from 'bootstrap';
import '../divineodyssey/divine.css';

const Panckosh = () => {
   const [readMore, setReadMore] = useState(false);
   const extraContent =
      <div>
         <p className='extra-content about discription'>
            Next, is pranamaya kosha or vital sheath, filled with life force and comprises of chakras, nadis and pranic vayus. It is our integrated spirit form and energy body along with emotions. Clairvoyants see pranic body as luminous aura pervading the physical body, as electromagnetic spectrum.
         </p>
         <p className='extra-content about discription'>
            Depending upon the blocks of inner subtle bodies, the aura is reflected in different colours, shades, luminosity, that tells the state of a person. It can be seen and read by spiritual readers and accordingly, the guidance is provided so that the blocks can be removed, and aura becomes vibrant leading to free flow of prana in the all the koshas. The pranic body is subtler than physical body and is the medium of flow between gross and subtle bodies. As we meditate or become aware, the pranic energy also evolves from gross to subtle and pushes away the blocks from chakras and nadis.  Any hindrance in the flow of energy here directly affects all the bodies which reflects in physical body in the form of various diseases.
         </p>
         <p className='extra-content about discription'>
            The third sheath is manomaya kosha, the mental dimension which can be categorised as lower and higher minds. It is the conscious mind which holds the above two sheaths. It is like a link between outer and inner worlds transmuting the experiences of our action senses (the five senses) to inner bodies and influences of inner subtle to outer body. Manomaya kosha is where human beings’ capability to analyse, apply logics, thought process, intuition, intelligence, wisdom happens.  This is where human consciousness begins, and this kosha is absent in plants and animals. If our consciousness is stuck in lower realms of mind, then the consciousness loses its power to mind and it races like a wild horse which gives rise to many mental disorders. As we become aware, we go deep and channel traits of higher mind like intuition and intelligence taming the horse little by little.
         </p>
         <p className='extra-content about discription'>
            The fourth sheath is vijanamaya or sat kosha, the psychic level of experience which pervades manomaya kosha but is subtler than it. It’s the link between individual consciousness and universal consciousness. Capability to decipher and decode higher cosmic knowledge comes once consciousness reaches this kosha. Awakening of this kosha leads to awakening of many spiritual gifts like inner visions, sights, higher wisdom to understand the complete picture and true reality of human existence. It’s this body where in many of our first majestic and spiritual experiences happens, everything just starts making sense. Our perceptions related to many aspects of life like human relations, life experiences, circumstances begin to change and a deeper understanding of each and everything sweeps in.
         </p>
         <p className='extra-content about discription'>
            As vijanamaya kosha becomes progressively cleared through the kriyas and mediation, one may get glimpses of more subtle bodies like chit kosh, anandmaya kosh, atamkosh. Here, the experiences and what we feel is very relative and varies from person to person. As breath ceases here, dynamic mind (thoughts) transforms and merges into still mind (thoughtless state). All the desires, attachments, karmic seeds start dissolving here. It’s a state of absolute knowledge and union of self and brahman. One begins to live in bliss and perceive each moment as emanation of own self, pure love, contentment, and truth. In true sense we rise above all the cycles of life - death and become a perfected being or Yogi (Sat- Chit - Anand)
         </p>
         <p className='extra-content about discription'>
            <i>Some references taken from the book “ Prana and Pranayama “ by Swami Niranjanananda Saraswati</i>
         </p>
      </div>
   const linkName = readMore ? 'Read Less' : 'Read More'
   return (
      <div>
         <div className='row'>
            <div className='col-md-12'>
               <div className='aboutHeading'>
                  <div className='heading'>Divine Oddyssey</div>
               </div>
               <div class="selcetrowborder">
                  <div class="selecterpoiner">
                     <div class="seletbarrow"></div>
                     <p class="textor">
                        <Image className='homeimage' src="/lotus1.png" alt="image"></Image>
                     </p>
                  </div>
               </div>
               <div className='image-section'>
                  <Image className='homeimage' src="/coursespagedesign.png" alt="image"></Image>
               </div>
               <div className='aboutdiscription'>
                  <div className='aboutchakars'>
                     <div className='aboutchakrasHeading'>
                        <h1>PANCH KOSH</h1>
                        <h1>OR</h1>
                        <h1>FIVE BODIES</h1>
                     </div>
                     <p>
                        Body, well as we hear the word body the first thing our mind takes us to is the physical body. This body that is made up of flesh and bones. The only body that is visible or seen by our naked eyes. But ever thought, apart from this gross body which we bring into use as we eat, drink, sleep, exercise, mate are all basic primal actions. In humans, much more deeper instincts takes place.
                     </p>
                     <p>
                        Thoughts are governed by our mind or dynamic pranic energy also has a body of its own. As we cannot see our thoughts, similarly, even this body is subtle and invisible, but drives our entire life. Similarly, it is with emotions and feelings like, pain, anger, love which is again, what we can feel and not see and drive the actions we take throughout our birth. Then, there are more casual and subtle bodies which we can feel once our consciousness starts to awaken deeply.
                     </p>
                     <p>
                        Due to illusion or maya, consciousness mostly stays in physical body, emotional body, mental body and pranic body, resulting in exhaust of pranic energy within these sheaths. The range of body and subsequent pranic energy is from gross to subtle. First sheath is annamaya kosha, anna means food and its very primal basis of existence, almost animalic, and if consciousness mostly stays here people are stuck in very basic existence that is only eating, earning, sleeping, mating cycle with a very low conscious level full of desires, materialism and miseries. Running in loop behind money, success, fame, crushing each other to be on top of some illusionary ladder, just like Darwin theory of survival of fittest.
                     </p>
                     {readMore && extraContent}
                     <span className='readMorePosition'>
                        <Button className='btnyog read-more-link' onClick={() => { setReadMore(!readMore) }} variant="link"><span>{linkName}</span></Button>
                     </span>
                     <table class="table table-bordered mt-4">
                        <thead>
                           <tr>
                              <th scope="col">Name of koshas (Yogic)</th>
                              <th scope="col">Name of Body (Western)</th>
                              <th scope="col">Traits</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td>ANNAMAYA KOSHA</td>
                              <td>PHYSICAL / UNCONSCIOUS MIND</td>
                              <td>Primal and basic desires</td>
                           </tr>
                           <tr>
                              <td>PRANAMAYA KOSHA</td>
                              <td>VITAL ENERGY / EMOTIONAL</td>
                              <td>Energy, Power, vitality, Emotions, memories, karmic seeds</td>
                           </tr>
                           <tr>
                              <td>LOWER MANOMAYA KOSHA</td>
                              <td>LOWER MIND/ CONSCIOUS MIND</td>
                              <td>Discrimination, logics, analytical, Judgemental thoughts</td>
                           </tr>
                           <tr>
                              <td>HIGHER MANOMAYA</td>
                              <td>HIGHER MIND</td>
                              <td>Intuition, intelligence, wisdom</td>
                           </tr>
                           <tr>
                              <td>VIJANAMAYA KOSHA OR SAT KOSH/ KARAN SHARIR</td>
                              <td># VIJANAMAYA KOSHA OR SAT KOSH/ KARAN SHARIR</td>
                              <td>Psychic intelligence, spiritual gifts, link between self and universal self, soul journey maps, Astral journeys to various dimensions</td>
                           </tr>
                           <tr>
                              <td>CHIT KOSH/ BRAHMNIC SHARIR</td>
                              <td>CASUAL BODY/ SUPERCONSCIOUS MIND</td>
                              <td>Merging with higher and divine selves, higher knowledge of cosmos and brahman</td>
                           </tr>
                           <tr>
                              <td>ANANDMAYA KOSHA/ NIRVANIC SHARIR</td>
                              <td>BLISS BODY/ SUPRA OR TRANSCENDENTAL MIND</td>
                              <td>Still mind, complete merging of atman and brahman, whole world becomes reflection of self</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
export default Panckosh;