import '../../../src/Component/header/header.css';
import { Nav, Container, Image } from 'react-bootstrap';
// import { AppBar, Button, Toolbar } from "@material-ui/core";
import { React, useEffect, useState } from "react";

const Header = () => {
   const [isLogged, setisLogged] = useState(false);

   useEffect(() => {
      checkStorage();
      return () => { };
   }, [isLogged]);
   function checkStorage() {
      if (localStorage.getItem("key")) {
         setisLogged(true);
      } else {
         setisLogged(false);
      }
   }

   // const logout = () => {
   //    localStorage.removeItem("key");
   //    localStorage.clear();

   //    setisLogged(false);
   //    window.location.href = '/login';

   //  };
   // const logout = () => {
   //    localStorage.clear();
   //    window.location.href = '/login';
   // }

   const [bgImage, handleImage] = useState(
      "/Yogbhaktilogo.png"
   );
   window.addEventListener("wheel", (e) => {
      if (e.deltaY > 0) {
         handleImage(
            "/Yogbhaktilogo.png"
         );
      } else {
         handleImage(
            ""
         );
      }
   });
   return (
      <div>
         <div class="videotag">
            <Container>
               <div class="row">
                  <div class="col-sm-12 p-0">
                     <video playsinline="playsinline" class="w-100 videoset" autoplay="autoplay" muted="muted" loop="true">
                        <source src="../YogBhaktiLogo.mp4" type="video/mp4" />
                     </video>
                  </div>
               </div>
            </Container>
            <div className='headsection'>
               <div className='row'>
                  <div className='col-xl-12 col-md-12'>
                     <div className='banner'>
                        {/* <Image className='bannerset' src="/header.png" alt="image"></Image>
                        <Image className='bannersetmobile' src="/header1.png" alt="image"></Image> */}
                        {/* <div className='curveheader'>
                           <div className='curveheadersurve'></div>
                           <div className='curveheadercurve'></div>
                        </div> */}
                     </div>
                  </div>
               </div>
               <Container>
                  <div className='row'>
                     <div className='col-lg-6 col-md-6'>
                        <div className='left-header'>
                           <Nav.Link href="/">
                              <div className='image-section'>
                                 <Image
                                    id="myimage"
                                    className='headerimage'
                                    src={bgImage}
                                    alt=""
                                 />
                              </div>
                           </Nav.Link>
                        </div>
                     </div>
                     <div className='col-lg-6 col-md-6'>
                        <div className='right-header'>
                           <Nav className="me-auto">
                              <Nav.Link href="https://www.instagram.com"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" /></svg></Nav.Link>
                              <Nav.Link href="https://www.youtube.com"><img src='https://cdn-icons-png.flaticon.com/512/1532/1532462.png' alt='' width="20" height="18" /></Nav.Link>

                              {/* <Nav.Link href="/login"><span className='embarktext'>embark IN</span></Nav.Link> */}
                           </Nav>
                        </div>
                     </div>
                  </div>

                  <div className='row'>
                     <div className='col-lg-6 col-md-6'>
                        <div className='left-top-header'>
                           <Nav className="me-auto">
                              <Nav.Link href="https://yogbhakti.me/#about" class="btn">About</Nav.Link>
                              <Nav.Link href="https://yogbhakti.me/#soulcalling" class="btn">Our Soul Calling</Nav.Link>
                              <Nav.Link href="https://yogbhakti.me/divine-odyssey" class="btn">Divine Odyssey</Nav.Link>
                              <Nav.Link href="https://yogbhakti.me/panchkosh" class="btn">Panchkosh</Nav.Link>
                              {/* <Nav.Link href="https://yogbhakti.me/divine-odyssey">Divine Odyssey</Nav.Link> */}
                              {/* <Nav.Link href="/faq">FAQ</Nav.Link> */}
                           </Nav>
                        </div>
                     </div>
                     <div className='col-lg-6 col-md-6'>
                        <div className='right-top-header'>
                           <Nav className="me-auto">
                              <Nav.Link href="/blog">Divine Wisdom</Nav.Link>
                              {/* <Nav.Link href="#">Find Treasures</Nav.Link> */}
                              {/* <Nav.Link href="/support">Supports Us</Nav.Link>


                              {!isLogged ? (
                              <Nav.Link href="/login">Login</Nav.Link>
                              ) : (
                                 <Nav.Link onClick={logout}>Logout</Nav.Link>
                              )} */}


                              {/* <Nav.Link href="/login">Login</Nav.Link> */}
                              {/* <Nav.Link onClick={logout}>Logout</Nav.Link> */}


                              {/* <Nav.Link href="#">Follow Us</Nav.Link> */}
                           </Nav>
                        </div>
                     </div>
                  </div>
               </Container>
            </div>
         </div>
      </div>
   )
}
export default Header;

