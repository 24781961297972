import React, { useState } from 'react';
import '../../page/home/home.css';
import { Button, Container, Image } from 'react-bootstrap';
import { } from 'bootstrap';
import '../divineodyssey/divine.css';

const Divine = () => {
   const [readMore, setReadMore] = useState(false);
   const extraContent =
      <div>
         <p className='extra-content about discription'>
            <i>References of chakra images from yogainandailylife.org</i>
         </p>
      </div>
   const linkName = readMore ? 'Read Less' : 'Read More'
   return (
      <div>
         <div className='row'>
            <div className='col-md-12'>
               <div className='aboutHeading'>
                  <div className='heading'>Divine Oddyssey</div>
               </div>
               <div class="selcetrowborder">
                  <div class="selecterpoiner">
                     <div class="seletbarrow"></div>
                     <p class="textor">
                        <Image className='homeimage' src="/lotus1.png" alt="image"></Image>
                     </p>
                  </div>
               </div>
               <div className='image-section'>
                  <Image className='homeimage' src="/coursespagedesign.png" alt="image"></Image>
               </div>
               <div className='aboutdiscription'>
                  <Container>
                     <div className='aboutchakars'>
                        <div className='aboutchakrasHeading'>
                           <h1 className='heading'>CHARKAS</h1>
                        </div>
                        <p className='about discription'>
                           The pranic body is fuelled by the chakras which literally means “wheel “or circle, but according to yogic context, a better translation is subtle highly powered vortices of energy in the body. They act as transformers to distribute energies through Nadis to different organs in physical body as well as switch for illuminating higher dimension of consciousness in subtle bodies. Below given table briefly illustrates what blocks the chakras and how these blocks can be removed.
                        </p>
                        {readMore && extraContent}
                        <span className='readMorePosition'>
                           <Button className='btnyog read-more-link' onClick={() => { setReadMore(!readMore) }} variant="link"><span>{linkName}</span></Button>
                        </span>
                        <table class="table mt-4">
                           <thead>
                              <tr style={{background: "silver"}}>
                                 <th scope="col">Name of Chakra</th>
                                 <th scope="col">Connection to Physical Body</th>
                                 <th scope="col">Underactive Chakra Blocks</th>
                                 <th scope="col">Overactive Chakra Blocks</th>
                                 <th scope="col">How to Balance</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr style={{background: "rgb(247 105 117)"}}>
                                 <th scope="row">Root Chakra (Mooladhara)</th>
                                 <td>Organs: Spine, Leg, Feet Diseases: Pains weaknesses in these organs</td>
                                 <td>Fears, Insecurities, Survival Crisis</td>
                                 <td>Anxiety, Panic Attacks, Threatening</td>
                                 <td>Feel Safe, Protected and feel that power within you to feel that protection. Let go of fears & Insecurities.</td>
                              </tr>
                              <tr style={{background: "rgb(255 171 101)"}}>
                                 <th scope="row">Sacral Chakra (Swadhishthana)</th>
                                 <td>Kidney, Bladder, Reproductive organs, prostrate, pancreas, large intestine</td>
                                 <td>Guilt, Blame, not able to accept reality, fear of change, poor social skills</td>
                                 <td>Over Emotional, Excessively Sensitive, pleasure addiction, obsessive attachment </td>
                                 <td>Forgive Yourself, let go of regrets, tame your desires, love yourself</td>
                              </tr>
                              <tr style={{background: "rgb(255 206 87)"}}>
                                 <th scope="row">Solar Chakra (Manipura)</th>
                                 <td>Stomach, Liver, Bladder, small Intestine, Spleen, Acidity issues</td>
                                 <td>Shame or disappointments, low energy, low self-esteem, doubting your own self, feeling powerless, victim mentality</td>
                                 <td>Aggression, Strong Ego, dominating, manipulative, hyperactivity, extremely ambitious, controlling, power hungry</td>
                                 <td>Believe in yourself, respect and accept others, feeling of unity, search inside the inner direction, Balance & love all aspects of yourself and others</td>
                              </tr>
                              <tr style={{background: "rgb(56 201 136)"}}>
                                 <th scope="row">Heart Chakra (Anahata)</th>
                                 <td>Circulatory system which includes heart, respiratory system, immune system, shoulders, upper back</td>
                                 <td>Grief, Pain, Sadness, Loss of loved ones leading to bitterness, dependency on others for own happiness, self-hatred, lack of expressing, less compassion, no gratefulness, complaining nature, cold, depression, bitter, narcissism, judgmental</td>
                                 <td>Losing your identity, No self-care, Tolerating too much, Co-Dependency, jealousy, demanding</td>
                                 <td>Unconditional Love and Compassion. Love is never lost just changes forms. Love your own self & everyone around you. Tap in endless supply of universal cosmic love, feel oneness</td>
                              </tr>
                              <tr style={{background: "rgb(108 200 239)"}}>
                                 <th scope="row">Throat Chakra (Vishuddhi)</th>
                                 <td>Throat, Neck, thyroid gland, sense organs (Vulnerable to cough and cold)</td>
                                 <td>Blocked by lies and denial of your true self, shyness, fear of communication</td>
                                 <td>Voice negative, too much trying hard to be heard, speaking in high pitch, gossiping, excessive criticism, verbal abuse</td>
                                 <td>Accept the true form of yours, who you are, conscious self control, positive thoughts, speak truth.</td>
                              </tr>
                              <tr style={{background: "rgb(112 133 188)"}}>
                                 <th scope="row">Third eye Chakra (Ajna) [Chakra of Light]</th>
                                 <td>Blocked by illusions of separation, leading to we judging others and forming opinions too much, denial</td>
                                 <td>Brain, Mind, Sense Organs, pineal gland Insomnia, Sinus, Weak sense organs</td>
                                 <td>Paranoia, Hallucination, too much obsessed with visions, nightmares, space out, delusions, headaches</td>
                                 <td>Everything is connected and we all have a past of some collective consciousness, Be open minded, overcome pride</td>
                              </tr>
                              <tr style={{background: "#d560bb"}}>
                                 <th scope="row">Crown Chakra (Sahasrara) [Chakra of Bliss]</th>
                                 <td>Organs: Upper Brain, Nervous System, pituitary gland Diseases: Depressions, Migraines</td>
                                 <td>Earthly attachments, giving too much power to loved ones, boredom, apathy, confusion, rigid belief systems, greed.</td>
                                 <td>Disconnect from world, people</td>
                                 <td>Unconditionally love, love yourself, Let go of things, Merge with your higher self, spiritual discipline</td>
                              </tr>
                           </tbody>
                        </table>
                        <table class="table table-bordered mt-4">
                           <thead>
                              <tr>
                                 <th scope="col">Name of chakra</th>
                                 <th scope="col">Koshas</th>
                                 <th scope="col">Bodies</th>
                                 <th scope="col">Elementor</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr>
                                 <th scope="row">ROOT/ MOOLADHARA</th>
                                 <td>ANNAMAYA</td>
                                 <td>PHYSICAL</td>
                                 <td>EARTH</td>
                              </tr>
                              <tr>
                                 <th scope="row">SACRAL/ SWADISHTHANA</th>
                                 <td>PRANAMAYA</td>
                                 <td>ENERGY/Emotional</td>
                                 <td>WATER</td>
                              </tr>
                              <tr>
                                 <th scope="row">SOLAR/ MANIPURA</th>
                                 <td>PRANAMAY AND LOWER MANOMAYA</td>
                                 <td>LOWER MENTAL</td>
                                 <td>FIRE</td>
                              </tr>
                              <tr>
                                 <th scope="row">HEART/ ANAHATA</th>
                                 <td>HIGHER MANOMAYA AND VIJANAMAY</td>
                                 <td>HIGHER MENTAL AND ASTRAL BODY HOME OF SOUL</td>
                                 <td>AIR</td>
                              </tr>
                              <tr>
                                 <th scope='row'>THROAT / VISSHUDHI</th>
                                 <td>VIJANAMAYA OR SAT KOSH/KARAN SHARIR</td>
                                 <td>Astdal</td>
                                 <td>Ether</td>
                              </tr>
                              <tr>
                                 <th scope='row'>THIRD EYE/ AJNA</th>
                                 <td>CHIT KOSH/ BRAHMNIC SHARIR</td>
                                 <td>CASUAL SOURCE OF SOUL CREATION</td>
                                 <td>Space</td>
                              </tr>
                              <tr>
                                 <th scope='row'>CROWN/ SAHASRARA</th>
                                 <td>ANANDMAYA KOSHA/ NIRVANIC SHARIR</td>
                                 <td>BLISS UNION OF SOUL AND CREATOR</td>
                                 <td>Void</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </Container>
               </div>
            </div>
         </div>
      </div>
   )
}
export default Divine;
