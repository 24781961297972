import React,{useState} from 'react';
import '../../page/home/home.css';
import {Button, Image } from 'react-bootstrap';
import { } from 'bootstrap';
import '../divineodyssey/divine.css';

const Prana = () => {
   const [readMore,setReadMore]=useState(false);
   const extraContent=
   <div>
      <p className='extra-content about discription'>
         Consciousness is what we are, “The awareness”, and prana is “Becoming, manifestation through motion”. More we get aware and detach ourselves from this divine play, like an observer, more the quantum of prana becomes pure. Purity of the prana at an individual level can be refined by higher feelings, positive thoughts, gaining higher knowledge, meditation, yogic kriyas and stillness of mind. This also directly improves human being’s health, longevity, cure diseases, and evolves the states to higher consciousness. If brain is not supplied with sufficient prana, we become irritable, and dive into negative thoughts. When prana is controlled by observing breath, flighty mind can be controlled too, and this also increases the quantum of prana. This also awakens the dormant parts of brain which are left unused throughout our life. A yogi has abundant pranic energy and therefore radiates good health, strength, love, and compassion all around them. Just as water flows in the method of osmosis, similarly, energy flows from high energy individual to low energy ones.
      </p>
      <p className='extra-content about discription'>
         At material level, the quality of prana varies according to the action and thoughts we indulge into and vibrate at certain frequencies. The sources of prana have certain quantum of prana and it affects directly and indirectly the individual prana in us. Andre Simoneton in France developed a method to see the pranic value of food using a pendulum to record the subtle radiations and specific wavelengths. It was found out that fresh fruits and vegetables have highest frequencies (10,000 angstorms) more than basic human wavelengths (6.5 thousand) while weak radiation foods cooked meat, sausages, junk food and processed food (3000 angstorms). Food having zero life force were alcoholic spirits, tobacco, white flour, white sugar etc. Perhaps it explains why consumption of such food causes the diseases because it’s way below basic human wavelengths thus causing the blocks. In our experiences, not just the type of food influences frequencies but also with what intent and thoughts we cook the food. Similar is the case with quality of air, the vitality we experience near nature, mountains, rivers, pilgrimages, is because the quantum of prana air is purer then polluted areas and cities.
      </p>
      <p className='extra-content about discription'>
         The individual prana within human beings is the part of universal prana and until the veil is lifted, we see ourselves separate from the cosmic matrix. Through yogic kriyas and pranayama we become conscious of prana which increases the existing quantum of prana. The prana is directed from root (Mooldhara) to third eye (Ajna) which generates heat and increases the frequency of it. If we can feel and see everything in form of energy and vibrations many amazing hidden mysteries can unfold, ultimately leading to transcendence of duality and having the state of Sat- Chit- Ananda, truth expansiveness and beatitude.
      </p>
      <p className='extra-content about discription'>
         <i>Some references taken from the book “ Prana and Pranayama “ by Swami Niranjanananda Saraswati</i>
      </p>
   </div>
   const linkName=readMore?'Read Less':'Read More'
   return (
      <div>
         <div className='row'>
            <div className='col-md-12'>
               <div className='aboutHeading'>
                  <div className='heading'>Divine Oddyssey</div>
               </div>
               <div class="selcetrowborder">
                  <div class="selecterpoiner">
                     <div class="seletbarrow"></div>
                     <p class="textor">
                        <Image className='homeimage' src="/lotus1.png" alt="image"></Image>
                     </p>
                  </div>
               </div>
               <div className='image-section'>
                  <Image className='homeimage' src="/coursespagedesign.png" alt="image"></Image>
               </div>
               <div className='aboutdiscription'>
                  <div className='aboutchakars'>
                     <div className='aboutchakrasHeading'>
                        <h1>PRANA</h1>
                     </div>
                     <p>
                        Prana is the life force or chi energy, that is present in each and every atom of this universe, the ‘PRAKRITI’ while consciousness is ‘PURUSHA’. It’s the motion behind all actions, motion in creation.  Prana is also called ‘Energy’ or ‘Vital Force’ but that’s a very rough translation. From the yogic point of view, the entire cosmos is alive and breathing because “of” and “with” prana. It is reason of the existence in all living beings and non living beings ranging from as gigantic as universes to as tiny as blade. Densities of pranic energy particles exists in different permutations and combinations, to create never ending matrix of creation. The core universal prana can be static or dynamic but it’s behind the lowest to highest forms of existence.
                     </p>
                     <p>
                        On earth, the source of prana to sustain physical body is air (Oxygen), Food, Water and Sun. The air we breathe is very gross form of prana but not in its entirety as many yogis can survive without breathing. It is when prana leaves the body and not oxygen, death occurs in living beings. Prana can be categorised into universal or maha prana and individual prana. Maha prana can also be the kundalini energy lying latent in the Mooldhara (root chakra). Maha prana is also called the cosmic mother or para shakti, the first primal energy from unmanifested consciousness (para brahman) to trigger the creation in cosmos. This interaction of energy (prakriti) and consciousness (purusha) in each other is what is called cosmic play or ‘Lila’ and it causes creation or ‘Srishti’. In Samkhya traditions, prakriti is also referred to as Shakti and purusha as Shiv. When prana is stilled, kundalini rises and merges with consciousness in Ajna chakra (Third Eye).
                     </p>
                     {readMore && extraContent}
                     <span className='readMorePosition'>
                        <Button className='btnyog read-more-link' onClick={()=>{setReadMore(!readMore)}} variant="link"><span>{linkName}</span></Button>
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
export default Prana;