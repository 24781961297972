import React,{useState} from 'react';
import './home.css';
import { Button, Image } from 'react-bootstrap';
import { } from 'bootstrap';
import "@fontsource/poppins";
const Home = () => {
   const [readMore,setReadMore]=useState(false);
   const extraContent=
   <div>
      <p className='extra-content about discription'>
         Similarly in the journey of the soul, as it descends and start to experience the action-reaction loops, it results in many low emotions like fear, pain, grief, insecurities, judgements in form of those sediments called blocks which human body manifest in form of various diseases and low state of mind. With yog such as pranakarma as we become aware of our consciousness, we channel the pure pranic energy through our Chakras (Energy Centres) and the three main Nadis (Ida, Pingala and Sushumuna) the flow becomes pure and we start to purify as our kinetic mind settles in our still mind. This causes the union of soul with the infinite soul through soul action (Yog) and finally the merging into ocean of absolute divine love ‘brahman’ occurs.
      </p>
      <p className='extra-content about discription'>
         There are many age-old paths mentioned in Vedas, Upanishad and religious books like Bhagwat Gita through which we achieve this ultimate tranquillity. Various paths are Karmic (Action), Jnana (Knowledge and Yog), bhakti (Devotion). Many follow only one path throughout and close themselves to follow the other ****ones. But, according to our experiences, amalgam of all the paths is very important to understand and experience the complete process. For example, without any action be it as simple as breathing or eating too taking steps to become better version of your own self we are unable to carry out a smooth existence, without Yog, blocks are not removed and the awareness to still your mind becomes very difficult to attain, and without bhakti or feeling love for ‘brahman’ (formless or form) even samadhi state is neutrally dull and black & white. After all, the whole cosmos was created from shunya (zero) and expanded to infinity in order to experience love in various forms.
      </p>
   </div>
   const extraContentOne=
   <div>
      <p className='extra-content about discription'>
         In order to listen to that inner calling and connect to your higher self with inner guidance, few courses have been designed at various levels which you can access by answering some questionnaires. “Divine odyssey” the name of this entire process, which means commencing of the soul journey inside out and vice versa. We are blessed enough to be that channel to help you in this deepest dive of your own self. The courses are free of cost because the gift we got from above how we can charge for that, our main intent is only to help and spread love as you are us and we are you. Though if anyone feels like supporting this cause with the right intent and out of love will be warm heartedly accepted. We also believe that somehow all things get done and help is received if our intent is right and pure.
      </p>
      <p className='extra-content about discription'>
         So, let’s embark this journey together, follow our soul calling and finally go back to our abode
      </p>
   </div>
   const linkName=readMore?'Read Less':'Read More'
   return (
      <div>
         <div className='row'>
            <div className='col-md-12'>
               <div className='yogabhakti'>
                  <div className='image-section'>
                     <Image className='homeimage' src="/aboutusdesign1.png" alt="image"></Image>
                  </div>
                  <div className='aboutTag'>
                     <div className='aboutHeading' id="about">
                        <div className='heading'>About Yogbhakti</div>
                     </div>
                     <div class="selcetrowborder1">
                        <div class="selecterpoiner">
                           <div class="seletbarrow"></div>
                           <p class="textor1">
                              <Image className='lotushomeimage' src="/lotus1.png" alt="image"></Image>
                           </p>
                        </div>
                     </div>
                     <div className='container'> 
                        <div className='row'>
                           <div className='col-md-12'>
                              <p className='about discription'>
                                 Though it is very difficult to even attempt to define Yogbhakti, just like it is impossible to measure the depth of an ocean yet will try to do. In simple words, ‘Yog’ means to purify the blocks and abandonment of all thoughts inside and eventually giving up the self, thus, transcending your ‘atman’. While bhakti, means, finally to merge in absolute love ’brahman’. It is like a journey from you to you. With Yog, we basically remove the age-old blocks which started to develop as soon as our soul experienced first human birth on earth in form of our karmic seeds which are embedded deep inside. As our physical and subtle bodies get pure, we can feel the pure divine love and eventually see our Atmic self in each and every being, thus transcending the duality and becoming bhakti itself.
                              </p>
                              <p className='about discription'>
                                 It is very similar to journey of a river which during its course of flowing absorb many rubbish deposits and sediments and in due course becomes sludge water. But if we start to clean the river, remove the blocks, it eventually again starts flowing freely and at the end merges into an ocean.
                              </p>                     
                              {readMore && extraContent}
                              <span className='readMorePosition'>
                                 <Button className='btnyog read-more-link' onClick={()=>{setReadMore(!readMore)}} variant="link"><span>{linkName}</span></Button>
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className='image-section'>
                     <Image className='homeimage' src="/aboutusdesign2.png" alt="image"></Image>
                  </div>
               </div>
               <div className='aboutdivineTag'>
                  {/* <div className='yogaimage'>
                     <div className='aboutHeading'>
                        <div className='heading'>DIVINE ODESSEY</div>
                        <div className='headingone'>(Aatma Ka Safar)</div>
                     </div>
                     <div class="selcetrowborder">
                        <div class="selecterpoiner">
                           <div class="seletbarrow"></div>
                           <p class="textor">
                              <Image className='lotushomeimage' src="/lotus1.png" alt="image"></Image>
                           </p>
                        </div>
                     </div>
                     <div className='image-section'>
                        <Image className='homeimageyog' src="/coursesdesign.png" alt="image"></Image>
                        <div className='preppingtitleone'> YB 1 Prepping</div>
                        <div className='preppingdescriptionone'>Lorem Ipsum is simply dummy text of the printing</div>
                        <div className='preppingbuttonone'>
                           <span className='readMorePositionone'>
                              <Button variant="link">View more</Button>
                           </span>
                        </div>
                        <div className='preppingtitlefour'> YB 2 Boarding</div>
                        <div className='preppingdescriptionfour'>Lorem Ipsum is simply dummy text of the printing</div>
                        <div className='preppingbuttonfour'>
                           <span className='readMorePositionone'>
                              <Button variant="link">View more</Button>
                           </span>
                        </div>
                        <div className='preppingtitletwo'> YB 3 Anchoring</div>
                        <div className='preppingdescriptiontwo'>Lorem Ipsum is simply dummy text of the printing</div>
                        <div className='preppingbuttontwo'>
                           <span className='readMorePositionone'>
                              <Button variant="link">View more</Button>
                           </span>
                        </div>
                        <div className='preppingtitlefive'> YB 4 Flowing</div>
                        <div className='preppingdescriptionfive'>Lorem Ipsum is simply dummy text of the printing</div>
                        <div className='preppingbuttonfive'>
                           <span className='readMorePositionone'>
                              <Button variant="link">View more</Button>
                           </span>
                        </div>
                        <div className='preppingtitlethree'> YB 5 Deep Diving</div>
                        <div className='preppingdescriptionthree'>Lorem Ipsum is simply dummy text of the printing</div>
                        <div className='preppingbuttonthree'>
                           <span className='readMorePositionone'>
                              <Button variant="link">View more</Button>
                           </span>
                        </div>
                        <div className='preppingtitlesix'> YB 6 Oneness</div>
                        <div className='preppingdescriptionsix'>Lorem Ipsum is simply dummy text of the printing</div>
                        <div className='preppingbuttonsix'>
                           <span className='readMorePositionone'>
                              <Button variant="link">View more</Button>
                           </span>
                        </div>
                        <div className='buttonsetting'>
                           <span className='readMorePositionone'>
                              <Button className='btnyog' variant="link">View more</Button>
                           </span>
                        </div>
                     </div>
                  </div> */}
                  <div className='yogabhakti'>
                     <div className='oursoulcalling' id="soulcalling">
                        <div className='heading'>OUR SOUL CALLING</div>
                     </div>
                     <div class="selcetrowborder2">
                        <div class="selecterpoiner">
                           <div class="seletbarrow"></div>
                           <p class="textor">
                              <Image className='lotushomeimage' src="/lotus1.png" alt="image"></Image>
                           </p>
                        </div>
                     </div>
                     <div className='image-section'>
                        <Image className='homeimage' src="/soulcallingdesign1.png" alt="image"></Image>
                     </div>
                     <div className='aboutTagone'>
                        <div className='container'> 
                           <div className='row'>
                              <div className='col-md-12'>
                                 <p className='about discription'>
                                    As the earth is ascending slowly to a new era and huge spiritual awakening is happening all over, we also had this inner spiritual calling to help human beings who are ready, and all geared up for it. We strongly believe that the spiritual experiences and divine love we received in this journey, we should share and expand that as far as we can. This whole process is like a beautiful symbiotic connection, as not only seekers gain and grow but also the one imparting knowledge learns a lot.   
                                 </p>
                                 <p className='about discription'>
                                    There is nothing new that we will be telling you, as your soul knows it all, just those veils need to be uplifted. Our purpose is to be just the medium of Parmatma (Divinity) helping you to listen to that voice, access the higher knowledge, merge into your cosmic selves and finally go back to your true home. As buddha said, I can only point fingers to the moon, show you the way, but what path you feel connected too, will be your choice and our soul is our truest guide and guru. The basic nature of soul is to be free, fly, flow and we will strive our best to help you find those wings to kickstart your journey and find your true purpose.
                                 </p>
                                 {readMore && extraContentOne}
                                 <span className='readMorePosition'>
                                    <Button className='btnyog read-more-link' onClick={()=>{setReadMore(!readMore)}} variant="link"><span>{linkName}</span></Button>
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className='image-section'>
                        <Image className='homeimage' src="/soulcallingdesign2.png" alt="image"></Image>
                     </div>
                  </div>
               </div>
               {/* <div className='testonomy'>
                  <div className='image-section'>
                     <Image className='homeimage' src="/Inkedtestimonialdesign.jpg" alt="image"></Image>
                  </div>
                  <div className='abouttextnomyHeading'>
                     <div className='heading'>YOGBHAKTI CHRONICHLES</div>
                     <div className='headingone'>(Roohani kahaniyan)</div>
                     <div className="selcetrowborder">
                        <div className="selecterpoiner">
                           <div className="seletbarrow"></div>
                           <p className="textor">
                              <Image className='lotushomeimage' src="/lotus1.png" alt="image"></Image>
                           </p>
                        </div>
                     </div>
                     <div className="testimonials-section">
                        <input type="radio" name="slider" title="slide1" className="slider__nav" />
                        <input type="radio" name="slider" title="slide2" className="slider__nav" />
                        <input type="radio" name="slider" title="slide3" className="slider__nav" />
                        <input type="radio" name="slider" title="slide4" className="slider__nav" />
                        <input type="radio" name="slider" title="slide5" className="slider__nav" />
                        <div className="slider__inner">
                           <div className="slider__contents">
                              <Image className='textomonial' src="https://static.remove.bg/remove-bg-web/5c20d2ecc9ddb1b6c85540a333ec65e2c616dbbd/assets/start_remove-c851bdf8d3127a24e2d137a55b1b427378cd17385b01aec6e59d5d4b5f39d2ec.png" alt="image"></Image>
                              <p className="slider__txt">You all bend over backwards to get it done. Inside sales and the Account Managers are great! It's your service...you all know that it's not just about taking orders it's about service. Why do we choose you guys - your people, your prices, you're quick and you have a ton of technical knowledge.</p>
                              <h2 className="slider__caption">Jared | Rexam</h2>
                           </div>
                           <div className="slider__contents">
                              <Image className='textomonial' src="https://www.pngall.com/wp-content/uploads/2016/04/Happy-Girl-Transparent.png" alt="image"></Image>
                              <p className="slider__txt">It's the long-term relationship we have with Proheat that keeps me calling you guys. I trust you, you're quick, and everybody I've ever spoken to there are all great people. Our CEO, Bill, talks about building relationships. That's exactly what Proheat does, and I couldn't be happier.</p>
                              <h2 className="slider__caption">Chris | C&M Fine Pack</h2>
                           </div>
                           <div className="slider__contents">
                              <Image className='textomonial' src="https://purepng.com/public/uploads/thumbnail//girls-uzj.png" alt="image"></Image>
                              <p className="slider__txt">You answer my questions, always takes care of problems, and I never have a hassle.</p>
                              <h2 className="slider__caption">Rex | LNP Engineering Plastics</h2>
                           </div>
                           <div className="slider__contents">
                              <Image className='textomonial' src="https://www.pngmart.com/files/5/Yoga-Girl-Transparent-Background.png" alt="image"></Image>
                              <p className="slider__txt">Proheat's staff are all so friendly and everybody goes above and beyond. Everyone is courteous, everything is quick and you get us what we need. I have to shop around for everything and we ALWAYS come back to Proheat.</p>
                              <h2 className="slider__caption">Darlene | Russel Stover</h2>
                           </div>
                        </div>
                     </div>
                  </div>
               </div> */}
              
            </div>
         </div>
      </div>
   )
}
export default Home;