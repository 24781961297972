import React from 'react';
import '../../page/home/home.css';
import { Button, Image, Nav } from 'react-bootstrap';
import { } from 'bootstrap';
import '../divineodyssey/divine.css';
import '../login/login.css'

const Login = () => {

    return (
        <div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='aboutHeading'>
                        <div className='heading'>EMBARK HERE</div>
                    </div>
                    <div class="selcetrowborder">
                        <div class="selecterpoiner">
                            <div class="seletbarrow"></div>
                            <p class="textor">
                                <Image className='homeimage' src="/lotus1.png" alt="image"></Image>
                            </p>
                        </div>
                    </div>
                    <div className='image-section'>
                        <Image className='homeimage' src="/coursespagedesign.png" alt="image"></Image>
                    </div>
                    <div className='aboutdiscription'>
                        <div className='loginpadding'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12'>
                                    <div className='enterrsone'>
                                        <form>
                                            <div className='requirefield'>
                                                User Name
                                            </div>
                                            <input className='testsetone' type="email" name="email"/>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12'>
                                    <div className='enterrsone'>
                                        <form>
                                            <div className='requirefield'>
                                                Password
                                            </div>
                                            <input className='testsetone' type="password" name="password"/>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12'>
                                    <div className='forgetpassword'>
                                        <form>
                                            <Nav className="me-auto">
                                                <Nav.Link href="/forgetpassword"><span className='requirefield'>Forget Password ?</span></Nav.Link>
                                            </Nav>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className='signinbutton'>
                                <span className='readMorePositionone'>
                                    <Button className='loginbutton' variant="link">embark in</Button>
                                </span>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                        <div className='enterrsone'>
                                            <form>
                                                <div className='requirefield'>
                                                    Or SignUP using
                                                </div>
                                            </form>
                                            <Nav className="signuplinks">
                                                <Nav.Link href="https://www.googleplus.com"><img src="https://img.icons8.com/ios/27/000000/google-plus.png" alt=''/></Nav.Link>
                                                <Nav.Link href="https://www.instagram.com"><img src="https://img.icons8.com/material-outlined/25/000000/instagram-new--v1.png" alt=''/></Nav.Link>
                                                <Nav.Link href="https://www.facebook.com"><img src="https://img.icons8.com/ios-glyphs/22/000000/facebook-f.png" alt=''/></Nav.Link>
                                            </Nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login;