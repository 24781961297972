import React from 'react';
import '../../../src/Component/Footer/footer.css';
import {Navbar, Nav, Container, Image} from 'react-bootstrap';
import instagram from "../../img/instagram-new.png";

const Footer = () => {
   return (
      <div>
         <div className='footersection'>
            <div className='row'>
               <Navbar className='header-top-section'>
                  <Container className='mt-5'>
                     <div className='col-6'>
                        <div className='bottom-footer'>
                           <div className='left-footer'>
                              <Nav.Link href="/">
                              <div className='image-section'>
                                 <Image className='footerlogo' src="/Yogbhaktilogo.png" alt="image"></Image>
                              </div>
                              </Nav.Link>
                              {/* <p className='footer-description'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                           </div>
                        </div>
                     </div>
                     <div className='col-3'>
                        <div className='center-footer'>
                           <h6>Quick Links</h6>
                           <span className="me-auto footerLinks">
                              <Nav.Link  href="https://yogbhakti.me/#about" class="btn">About</Nav.Link >
                              <Nav.Link href="https://yogbhakti.me/#soulcalling" class="btn">Our Soul Calling</Nav.Link >                  
                              <Nav.Link href="#">Contact</Nav.Link>
                           </span>
                        </div>
                     </div> 
                     <div className='col-3'>
                        <div className='right-footer'>
                           <h6>Support</h6>
                           <span className="me-auto footerLinks">
                              <Nav.Link href="#">Help</Nav.Link>
                              <Nav.Link href="#">Privacy Policy</Nav.Link>
                              <Nav.Link href="#">Terms &amp; Conditions</Nav.Link>
                           </span>
                        </div>
                     </div> 
                  </Container>                                                                                                                                                                                                                                                                                               
               </Navbar>               
            </div>
            <div className='row'>
               <Navbar className='header-top-section'>
                  <Container>
                  <div className='col-8 '>
                        <div className='center-footer'>
                           <Nav>
                              <span className='copyright'> Copyright &copy; 2022-<script>document.write(new Date().getFullYear())</script> 
                              Yogbhakti. All Rights Reserved, Donate By <a className='copyright' href="http://elcodigocompany.com/"> &nbsp;El Codigo Company</a></span>
                           </Nav>
                        </div>
                     </div> 
                     <div className='col-4'>
                        <div className='right-footer'>
                           <span className="me-auto footerLinks">
                           <Nav className="me-auto">
                              <Nav.Link href="https://www.instagram.com"><Image src={instagram} alt="image"/></Nav.Link>
                              <Nav.Link href="https://www.youtube.com"><Image src='https://cdn-icons-png.flaticon.com/512/1384/1384028.png' alt="image" width="28" height="26"/></Nav.Link>&nbsp;
                           </Nav>
                           </span>
                        </div>
                     </div>                   
                  </Container>                                                                                                                                                                                                                                                                                               
               </Navbar>                          
            </div>
         </div>
      </div>
   )
}
export default Footer;