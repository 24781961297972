import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Blogs from './Component/page/blog/blogcomponent';
import HomePage from './Component/page/home/homecomponent.js';
import Divine from './Component/page/divineodyssey/devinecomponent.js';
import Prana from './Component/page/prana/pranacomponent.js';
import Panchvaus from './Component/page/panchvayus/panchvayuscomponent.js';
import Panckosh from './Component/page/panchkosh/panchkoshcomponent.js';
import Naddis from './Component/page/naadis/naddiscomponent.js';
import Support from './Component/page/support/supportcomponent.js';
import Pripping from './Component/page/pripping/prippingcomponent.js';
// import Faq from './Component/page/FAQ/faqcomponent.js';
import Login from './Component/page/login/logincomponent.js';
import SingleBlog from './Component/page/blog/singleblog';

const App = () => (
   <Router>
      <Routes>
         <Route exact path="/" element={<HomePage />} />
      </Routes>

      <Routes>
         <Route exact path="/single-post/:id" element={<SingleBlog />} />
      </Routes>

      <Routes>
         <Route exact path="/blog" element={<Blogs />} />
      </Routes>
      <Routes>
         <Route exact path="/divine-odyssey" element={<Divine />} />
      </Routes>
      <Routes>
         <Route exact path="/prana" element={<Prana />} />
      </Routes>
      <Routes>
         <Route exact path="/panchvayus" element={<Panchvaus />} />
      </Routes>
      <Routes>
         <Route exact path="/panchkosh" element={<Panckosh />} />
      </Routes>
      <Routes>
         <Route exact path="/naddis" element={<Naddis />} />
      </Routes>
      <Routes>
         <Route exact path="/support" element={<Support />} />
      </Routes>
      <Routes>
         <Route exact path="/pripping" element={<Pripping />} />
      </Routes>
      {/* <Routes>
         <Route exact path="/faq" element={<Faq />} />
      </Routes> */}
      <Routes>
         <Route exact path="/login" element={<Login />} />
      </Routes>
   </Router>
)
export default App;

