import React, { useState } from 'react';
import '../../page/home/home.css';
import { Button, Container, Image } from 'react-bootstrap';
import { } from 'bootstrap';
import '../support/support.css';
import { FaRegHeart } from 'react-icons/fa';

const Support = () => {
   const [hide, setShow] = useState(false)
   const [show, setHide] = useState(false)
   
   return (
      <div>
         <Container>
            <div className='row'>
               <div className='col-md-12'>
                  <div className='aboutdivineTag'>
                     <div className='aboutHeading' id="soulcalling">
                        <div className='heading'>SUPPORT US</div>
                     </div>
                     <div class="selcetrowborder">
                        <div class="selecterpoiner">
                           <div class="seletbarrow"></div>
                           <p class="textor">
                              <Image className='homeimage' src="/lotus1.png" alt="image"></Image>
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className='supporttitle'>
                     <div className='supportheading'>
                        <div className='aboutchakrasHeading'>
                           <div className='support'>Thank you for your support</div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className='support-image'>
               <div className='image-sectionsupport'>
                  <Image className='supportimage' src="/donatepage.png" alt="image"></Image>
               </div>

               <div className='support-design'>
                  <div className='supporttitle'>
                     <div className='aboutchakrasHeading'>
                        <div className='supportplan'>Choose Amount</div>
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-lg-2 col-md-2 col-sm-2'>
                        <div className='rs'>
                           <div className='tagging'>&#8377; 11</div>
                        </div>
                     </div>
                     <div className='col-lg-10 col-md-10 col-sm-10'>
                        <div className='enterrs'>
                           <form>
                              <input className='testsetone' type="text" placeholder='Denomination' />
                           </form>
                        </div>
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='enterrsone'>
                           <form>
                              <div className='nametag'>
                                 Full Name
                              </div>
                              <input className='testsetone' type="text" name="name" />
                           </form>
                        </div>
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-lg-5 col-md-5 col-sm-5'>
                        <div className='enterrsone'>
                           <form>
                              <div className='nametag'>
                                 Email ID
                              </div>
                              <input className='testsetone' type="email" name="email"/>
                           </form>
                        </div>
                     </div>
                     <div className='col-lg-2 col-md-2 col-sm-2'></div>
                  <div className='col-lg-5 col-md-5 col-sm-5'>
                     <div className='enterrsone'>
                        <form>
                           <div className='nametag'>
                              Phone Number
                           </div>
                           <input className='testsetone' type="tel" name="phone" />
                        </form>
                     </div>
                  </div>
                  </div>
                  <div className='row'>
                     <div className='col-lg-5 col-md-5 col-sm-5'>
                        <div className='inputtext'>
                           <label className="switch2">
                              <div className='donate'>
                                 <input type="checkbox" />
                                 <span className="slider2"></span>
                                 <div className='textone'>
                                    <span className='texttexttwo'>Donate Anonymously</span>
                                 </div>
                              </div>
                           </label>
                        </div>
                     </div>
                     <div className='col-lg-2 col-md-2 col-sm-2'></div>
                     <div className='col-lg-5 col-md-5 col-sm-5'>
                        <div className='inputtext'>
                           <label className="switch2">
                              <div className='donate'>
                                 <input onClick={() => setShow(!hide)} type="checkbox" />
                                 <span className="slider2"></span>
                                 <span className='textone'>Donate on someone else's behalf</span>
                              </div>
                           </label>
                           {
                              hide ? <div className='enterrsone'>
                                 <form>
                                    <div className='nametag-user'>
                                       Name
                                    </div>
                                    <input className='testsetone' type="text" name="subject"/>
                                 </form>
                              </div> : null
                           }
                        </div>
                     </div>
                  </div>
                  {/* <div className='row'>
                  <div className='col-lg-5 col-md-5 col-sm-5'>
                     <label>
                        <div className='donate'>
                           <span className='texttwo'>Your Donation will be marked Anonymous</span>
                        </div>
                     </label>
                  </div>
                  <div className='col-lg-2 col-md-2 col-sm-2'></div>
                  <div className='col-lg-5 col-md-5 col-sm-5'>
                     <label>
                        <div className='donate'>
                           <span className='texttwo'>Your Donation will be marked Anonymous</span>
                        </div>
                     </label>
                  </div>
               </div> */}

                  <div className='row'>
                     <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='inputtextserve'>
                           <label className="switch2">
                              <div className='donate'>
                                 <input onClick={() => setHide(!show)} type="checkbox" />
                                 <span className="slider2"></span>
                                 <span className='textone'>"Any heart touching and motivational note for us will be heighly appriciated"</span>
                                 {
                                    show ? <textarea placeholder='Enter your motivational note' name="message"></textarea> : null
                                 }
                                 
                              </div>
                           </label>
                        </div>
                     </div>
                  </div>
                  <div className='row'>
                     <div className='col-lg-12 col-md-12 col-sm-12'>
                        <div className='rsone'>
                           <Button className='tagging btnone'>
                              <FaRegHeart />
                              <span className='leftsupport'>Support US</span></Button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Container>
      </div>
   )
}
export default Support;