import React from 'react';
import '../home/home.css';
import '../pripping/pripping.css';
import { Button, Image } from 'react-bootstrap';
import { } from 'bootstrap';
import { FaRegHeart } from 'react-icons/fa';
const Pripping = () => {
    return (
        <div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='aboutHeading'>
                        <div className='heading'>Divine Oddyssey</div>
                    </div>
                    <div class="selcetrowborder">
                        <div class="selecterpoiner">
                            <div class="seletbarrow"></div>
                            <p class="textor">
                                <Image className='homeimage' src="/lotus1.png" alt="image"></Image>
                            </p>
                        </div>
                    </div>
                    <div className='image-section'>
                        <Image className='bgimagecolor' src="/aboutusdesign1.png" alt="image"></Image>
                    </div>
                    <div className='videoframetop'>
                        <div className='aboutchakars'>
                            <div className='aboutHeading'>
                                <div className='heading'>Pripping</div>
                            </div>
                            <div className='videoframe'>
                                <iframe title='unique title property' width="420" height="345" src="https://www.youtube.com/embed/tgbNymZ7vqY">
                                </iframe>
                            </div>
                            <div className='videoframe'>
                                <iframe title='unique title property' width="420" height="345" src="https://www.youtube.com/embed/tgbNymZ7vqY">
                                </iframe>
                            </div>
                        </div>
                    </div>
                    <div className='image-section'>
                        <Image className='bgimagecolor' src="/aboutusdesign2.png" alt="image"></Image>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-6'>
                    <div className='rsone'>
                        <Button className='tagging'>
                            <span className='leftsupport'>GROUP DISSCUSSION</span></Button>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6'>
                    <div className='rsone'>
                        <Button className='tagging'>
                            <span className='leftsupport'>PSYCHIC READING</span></Button>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12 col-md-12'>
                    <div className='rsone'>
                        <Button className='tagging'>
                            <FaRegHeart />
                            <span className='leftsupport'>Support US</span></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Pripping;