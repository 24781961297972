import React, { useState, useEffect } from 'react';
import './blog.css';
import { Image } from 'react-bootstrap';
import Header from '../../header/header';
import Footer from '../../Footer/footer';
import { blogServices } from "../../../_services/blogServices.js"

function SingleBlog() {

    const [items, setitems] = useState([]);

    const url = window.location.href;
    const parts = url.split("/");
    const path = parts[4];

    useEffect(() => {
        singleBlogDetails(path);
    }, [path]);

    const singleBlogDetails = async (path) => {
        let data = await blogServices.singleBlogDetails(path);
        setitems(data.user_data);
    };

    return (
        <div>
            <Header />
            <div className='row'>
                <div className='col-lg-12 mb-5'>
                    <div className='aboutblogHeading'>
                        <div className='heading'>{items.title}</div>
                    </div>
                    <div class="selcetrowborder">
                        <div class="selecterpoiner">
                            <div class="seletbarrow"></div>
                            <p class="textor">
                                <Image className='homeimage' src="/lotus1.png" alt="image"></Image>
                            </p>
                        </div>
                    </div>

                    <div className='single_bolg_content'>
                        <div className='single_blog'>
                            {items.description}
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}
export default SingleBlog;