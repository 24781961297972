import React from "react";
import Header from "../../header/header";
import Footer from "../../Footer/footer";
import Naddis from "./naddis";

const PunchkoshPage = () => {
    return(
        <div>
        <Header />
            <Naddis />
        <Footer />
    </div>
    )
}
export default PunchkoshPage;