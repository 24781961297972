import React from "react";
import Header from "../../header/header";
import Footer from "../../Footer/footer";
import Login from "./login";

const LoginPage = () => {
    return(
        <div>
        <Header />
            <Login />
        <Footer />
    </div>
    )
}
export default LoginPage;